import { Button, Form, FormProps, Input } from "antd";
import { useEffect } from "react";
import ReactQuill from "react-quill";
import { createHash, removeHash } from "../../helpers/siteConfig";
import { updateFaqAsync } from "../../store/features/Faq/faqAPI";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { CustomModalProps } from "../../types";
import { GetExpertCategoryData } from "../../types/redux";
import CustomModal from "../common/CustomModal";
import { formats } from "../Editor/Formats";
import { modules } from "../Editor/Modules";

type Props = {
  type: "ADD" | "EDIT" | "VIEW";
  reRender: (val: boolean) => void;
  changeId: string;
} & CustomModalProps;

export default function FaqQuestionModal({
  reRender,
  type,
  data,
  changeId,
  ...rest
}: Props) {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { postLoading, updateLoading } = useAppSelector((state) => state.faqs);

  const categoryData = data as GetExpertCategoryData;
  const onFinish: FormProps<any>["onFinish"] = (values) => {
    if (type === "EDIT" && changeId) {
      const newValues = {
        ...data,
        faqs: data?.faqs?.map((el: any) => {
          if (el?._id === changeId) {
            return {
              name: values?.name,
              Details: createHash(values?.Details),
              _id: el?._id,
            };
          }
          return el;
        }),
      };

      dispatch(
        updateFaqAsync({
          data: newValues,
          others: {
            reRender,
            id: categoryData?._id,
          },
        })
      );
    } else {
      const newValues = {
        ...data,
        faqs: [
          ...data?.faqs,
          {
            name: values?.name,
            Details: createHash(values?.Details),
          },
        ],
      };

      dispatch(
        updateFaqAsync({
          data: newValues,
          others: {
            reRender,
            id: categoryData?._id,
          },
        })
      );
    }
  };

  // add value in modal for view and edit
  useEffect(() => {
    if (
      (type === "EDIT" || type === "VIEW") &&
      changeId &&
      updateLoading === false
    ) {
      const findData = data?.faqs?.find((el: any) => el?._id === changeId);

      form.setFieldsValue({
        name: findData?.name || undefined,
        Details: findData?.Details ? removeHash(findData?.Details) : "",
      });
    }
  }, [changeId, data?.faqs, form, type, updateLoading]);

  return (
    <CustomModal width={600} {...rest}>
      <div className="p-6">
        <Form
          className="custom-form custom-placeholder-form hide_star_mark"
          layout="vertical"
          onFinish={onFinish}
          size="large"
          form={form}
        >
          {/* <Form.Item
            name="category"
            label="FAQ’s Category"
            className="col-span-2"
            rules={[
              {
                required: true,
                message: "Please select category",
              },
            ]}
          >
            <Select
              placeholder="Select"
              size="large"
              allowClear
              showSearch
              filterOption={selectSearchOption}
              options={[
                {
                  label: values?.category,
                  value: values?.category,
                },
              ]}
              disabled={type === "VIEW"}
            />
          </Form.Item> */}

          <Form.Item
            name={"name"}
            label="Question"
            rules={[
              {
                required: true,
                message: "Please enter question",
              },
            ]}
          >
            <Input
              size="large"
              disabled={type === "VIEW"}
              placeholder="Question"
            />
          </Form.Item>

          <Form.Item
            name={"Details"}
            label="Question Answer"
            className="md:col-span-2"
          >
            <ReactQuill
              className="h-[100px] w-full"
              theme="snow"
              formats={formats}
              modules={modules}
              readOnly={type === "VIEW"}
            />
          </Form.Item>

          <br />
          <br />
          {type !== "VIEW" ? (
            <div className="flex justify-center">
              <Button
                className="mt-2 px-[15px] py-[8px] rounded-lg text-[14px] font-semibold leading-6  "
                type="primary"
                htmlType="submit"
                loading={postLoading || updateLoading}
              >
                {type === "EDIT" ? "Update Now" : "Create Now"}
              </Button>
            </div>
          ) : null}
        </Form>
      </div>
    </CustomModal>
  );
}
