import {
  Badge,
  Button,
  Drawer,
  Dropdown,
  Empty,
  Layout,
  Menu,
  MenuProps,
  theme,
} from "antd";
import { useEffect } from "react";
import { Link, Outlet, useLocation, useParams } from "react-router-dom";
import withSidebar from "../HOC/withSidebar";
import BsrmAppSettings from "../assets/icons/sidebar/BsrmAppSettings";
import BsrmBlog from "../assets/icons/sidebar/BsrmBlog";
import BsrmBrandList from "../assets/icons/sidebar/BsrmBrandList";
import BsrmCustomerManage from "../assets/icons/sidebar/BsrmCustomerManage";
import BsrmDashboard from "../assets/icons/sidebar/BsrmDashboard";
import BsrmList from "../assets/icons/sidebar/BsrmList";
import BsrmLiveChat from "../assets/icons/sidebar/BsrmLiveChat";
import BsrmLocation from "../assets/icons/sidebar/BsrmLocation";
import BsrmManagProduct from "../assets/icons/sidebar/BsrmManagProduct";
import BsrmManageOrder from "../assets/icons/sidebar/BsrmManageOrder";
import BsrmManageRetail from "../assets/icons/sidebar/BsrmManageRetail";
import BsrmNotification from "../assets/icons/sidebar/BsrmNotification";
import BsrmNotificationHeader from "../assets/icons/sidebar/BsrmNotificationHeader";
import BsrmOurExpert from "../assets/icons/sidebar/BsrmOurExpert";
import BsrmProductCategory from "../assets/icons/sidebar/BsrmProductCategory";
import BsrmSalesReport from "../assets/icons/sidebar/BsrmSalesReport";
import BsrmScratchType from "../assets/icons/sidebar/BsrmScratchType";
import BsrmSettings from "../assets/icons/sidebar/BsrmSettings";
import BsrmSidebarToggol from "../assets/icons/sidebar/BsrmSidebarToggol";
import BSRM_FAVICON from "../assets/images/favicon.svg";
import BSRM_LOGO from "../assets/images/login/BSRM_Logo.svg";
import MainLogo_G from "../assets/images/login/G.svg";
import MainLogo from "../assets/images/login/Ghorbari_Logo.svg";
import Image from "../components/common/Image";
import Loader from "../components/common/Loader";
import ProfileMenu from "../components/common/ProfileMenu";
import LanguageSwitch from "../components/ui/LanguageSwitch";
import { APP_NAME, IMAGE_URL } from "../helpers/config";
import { IMG } from "../helpers/siteConfig";
import { getAllAdminNotificationAsync } from "../store/features/adminNotification/adminNotificationAPI";
import { getAllNotificationAsync } from "../store/features/notification/notificationAPI";
import { useAppDispatch, useAppSelector } from "../store/store";
import { activePathString, pathString, stringToArray } from "../utils/array";

const { Header, Sider, Content } = Layout;

type MenuItem = Required<MenuProps>["items"][number];

export type SidebarProps = {
  isMobile: boolean;
  collapsed: boolean;
  openSidebar: boolean;
  openMenuKeys: string[];
  handleOpenMenu: () => void;
  handleCollapsed: () => void;
  handleShowSidebar: () => void;
  handleCloseSidebar: () => void;
};

const SidebarLayout: React.FC<SidebarProps> = ({
  isMobile,
  collapsed,
  openSidebar,
  openMenuKeys,
  handleOpenMenu,
  handleCollapsed,
  handleShowSidebar,
  handleCloseSidebar,
}) => {
  let dynamicParams = useParams();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { siteRole } = useAppSelector((state) => state.login);
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const { adminHeaderNotification, singleLoading } = useAppSelector(
    (state) => state.adminNotification
  );
  const { retailerHeaderNotification, singleLoading: retailerSingleLoading } =
    useAppSelector((state) => state.notification);

  const isRETAILER = stringToArray(location.pathname, "/")?.[0] === "retailer";

  const dynamicPath = Object.keys(dynamicParams || {})?.length;

  function getItem(
    label: React.ReactNode,
    path: string,
    icon?: React.ReactNode,
    children?: MenuItem[],
    roles?: string[]
  ): MenuItem {
    return {
      key: pathString(path),
      icon,
      children,
      label: children?.length ? label : <Link to={path}>{label}</Link>,
      roles,
    } as MenuItem;
  }

  const notificationData = (
    isRETAILER ? retailerHeaderNotification : adminHeaderNotification
  )?.slice(0, 5);

  const AdminMenuItems: MenuItem[] = [
    getItem("Dashboard", "/admin/dashboard", <BsrmDashboard />),

    getItem("Scratch Type", "/admin/scratch-type", <BsrmScratchType />),

    getItem(
      "Product Category",
      "/admin/product-category",
      <BsrmProductCategory />,
      [
        getItem("Category", "/admin/product-category/category", <BsrmList />),
        getItem(
          "Ready Mix",
          "/admin/product-category/ready-mix-cement",
          <BsrmList />
        ),
      ]
    ),

    getItem("Product Brand", "/admin/Product-brandList", <BsrmBrandList />),

    getItem("Region", "/admin/location", <BsrmLocation />, [
      getItem("Division", "/admin/location/division-list", <BsrmList />),
      getItem("District", "/admin/location/district-list", <BsrmList />),
    ]),

    getItem(
      "Manage Retailers",
      "/admin/manage-retailers",
      <BsrmManageRetail />
    ),

    getItem("Manage Products", "/admin/manage-products", <BsrmManagProduct />, [
      getItem(
        "All Product",
        "/admin/manage-products/all-products",
        <BsrmList />
      ),
      getItem(
        "Pending Product",
        "/admin/manage-products/pending-products",
        <BsrmList />
      ),
    ]),

    getItem("Manage Order", "/admin/manageOrder", <BsrmManageOrder />),

    getItem("Our Expert", "/admin/our-category", <BsrmOurExpert />, [
      getItem(
        "Expert Category",
        "/admin/our-category/export-category",
        <BsrmList />
      ),
      getItem(
        "Manage Expert",
        "/admin/our-category/manage-expert",
        <BsrmList />
      ),
    ]),
    getItem("Live Chat", "/admin/liveChat", <BsrmLiveChat />),
    getItem("Notification", "/admin/notification", <BsrmNotification />),
    getItem("Customer Manage", "/admin/customerManage", <BsrmCustomerManage />),
    getItem("Special Journey Blog", "/admin/blog", <BsrmBlog />, [
      getItem("Post Category", "/admin/blog/blog-category", <BsrmList />),
      getItem("Post", "/admin/blog/blog-post", <BsrmList />),
    ]),

    getItem("App Settings", "/admin/appSettings", <BsrmAppSettings />, [
      getItem("App Slider", "/admin/appSettings/app-slider", <BsrmList />),
      getItem(
        "Privacy",
        "/admin/appSettings/privacy-confidentiality",
        <BsrmList />
      ),
      getItem(
        "Terms & Condition",
        "/admin/appSettings/terms-condition",
        <BsrmList />
      ),
      getItem("FAQs", "/admin/appSettings/faq", <BsrmList />),

      getItem(
        "Refund Policy",
        "/admin/appSettings/refund-policy",
        <BsrmList />
      ),
      getItem("About us", "/admin/appSettings/about-us", <BsrmList />),
      getItem("Bank List", "/admin/appSettings/bank-list", <BsrmList />),
    ]),

    getItem("Report", "/admin/manageOrder", <BsrmSalesReport />, [
      // getItem("Order List", "/admin/salesReport/orderList", <BsrmList />),
      getItem("Order List", "/admin/manageOrder", <BsrmList />),
      // getItem(
      //   "Product Sales Report",
      //   "/admin/salesReport/productSalesReport",
      //   <BsrmList />
      // ),
    ]),

    getItem("Settings", "/admin/settings", <BsrmSettings />),
  ];

  const RetailMenuItems: MenuItem[] = [
    getItem("Dashboard", "/retailer/dashboard", <BsrmDashboard />),
    getItem(
      "Manage Products",
      "/retailer/manage-products",
      <BsrmManagProduct />,
      [
        getItem(
          "All Product",
          "/retailer/manage-products/all-products",
          <BsrmList />
        ),
        getItem(
          "Pending Product",
          "/retailer/manage-products/pending-products",
          <BsrmList />
        ),
      ]
    ),

    getItem("Manage Order", "/retailer/manage-order", <BsrmManageOrder />),
    getItem("Live Chat", "/retailer/live-chat", <BsrmLiveChat />),
    getItem("Notification", "/retailer/notification", <BsrmNotification />),
    getItem("Report", "/retailer/report", <BsrmSalesReport />, [
      // getItem("Order List", "/admin/salesReport/orderList", <BsrmList />),
      getItem("Order List", "/retailer/report/order-list", <BsrmList />),
      // getItem(
      //   "Product Sales Report",
      //   "/admin/salesReport/productSalesReport",
      //   <BsrmList />
      // ),
    ]),
    getItem("Settings", "/retailer/settings", <BsrmSettings />),
  ];

  function getActiveMenu(data: any[]) {
    const strArr = stringToArray(location.pathname, "/");
    return data?.find((el) => el?.key === strArr[1])?.children ? true : false;
  }

  useEffect(() => {
    if (isRETAILER) {
      dispatch(
        getAllNotificationAsync({
          params: {
            limit: 0,
            pageNo: 0,
            fromDate: "",
            toDate: "",
            isHeader: true,
          },
        })
      );
    } else {
      dispatch(
        getAllAdminNotificationAsync({
          params: {
            limit: 0,
            pageNo: 0,
            fromDate: "",
            toDate: "",
            isHeader: true,
          },
        })
      );
    }
  }, [dispatch, isRETAILER]);

  return (
    <Layout className="min-h-screen">
      <div className="md:hidden">
        <Drawer
          placement="left"
          closable={false}
          onClose={handleCloseSidebar}
          open={openSidebar}
          key="left"
          contentWrapperStyle={{ width: "auto" }}
          bodyStyle={{ padding: 0 }}
        >
          <Sider
            trigger={null}
            theme="dark"
            collapsible
            collapsed={false}
            className="!overflow-auto !h-screen custom-sidebar"
            width={310}
          >
            <div className="h-20 flex justify-center items-center">
              <Link
                to={isRETAILER ? "/retailer/dashboard" : "/admin/dashboard"}
              >
                <Image width={55} src={MainLogo} alt={APP_NAME} />
              </Link>
            </div>

            <Menu
              theme="dark"
              mode="inline"
              selectedKeys={[
                activePathString(
                  location.pathname,
                  "/",
                  dynamicPath ? true : false,
                  getActiveMenu(
                    siteRole === "ADMIN"
                      ? AdminMenuItems
                      : siteRole === "RETAILER"
                      ? RetailMenuItems
                      : []
                  )
                ),
              ]}
              openKeys={openMenuKeys}
              onOpenChange={handleOpenMenu}
              items={
                siteRole === "ADMIN"
                  ? AdminMenuItems
                  : siteRole === "RETAILER"
                  ? RetailMenuItems
                  : ([] as MenuItem[])
              }
              className="sidebar-menu-item"
            />
          </Sider>
        </Drawer>
      </div>
      <div className="hidden md:block">
        <Sider
          trigger={null}
          theme="dark"
          collapsible
          collapsed={collapsed}
          className="!overflow-auto !h-screen !sticky top-0 custom-sidebar"
          width={310}
        >
          <div className={`flex justify-start items-center p-[23.7px] `}>
            <Link to={isRETAILER ? "/retailer/dashboard" : "/admin/dashboard"}>
              <Image
                width={collapsed ? 16 : 110.058}
                src={collapsed ? MainLogo_G : MainLogo}
                alt={APP_NAME}
              />
            </Link>
          </div>
          <hr className="hr" />
          <div className="mt-6">
            <Menu
              theme="dark"
              mode="inline"
              selectedKeys={[
                activePathString(
                  location.pathname,
                  "/",
                  dynamicPath ? true : false,
                  getActiveMenu(
                    siteRole === "ADMIN"
                      ? AdminMenuItems
                      : siteRole === "RETAILER"
                      ? RetailMenuItems
                      : []
                  )
                ),
              ]}
              openKeys={openMenuKeys}
              onOpenChange={handleOpenMenu}
              items={
                siteRole === "ADMIN"
                  ? AdminMenuItems
                  : siteRole === "RETAILER"
                  ? RetailMenuItems
                  : ([] as MenuItem[])
              }
              className={`sidebar-menu-item ${
                collapsed ? "sidebar_collapsed" : ""
              }`}
            />
          </div>
          <br />
          <br />
          <br />

          <div
            className={`px-[24px] pt-0 pb-8 ${
              isRETAILER ? "absolute bottom-0 bg-white w-full" : ""
            }`}
          >
            {collapsed ? null : (
              <p className="text-header text-[10.5px] ">Powered by</p>
            )}

            {collapsed ? (
              <Image
                width={collapsed ? 30 : 0}
                src={BSRM_FAVICON}
                alt={APP_NAME}
              />
            ) : (
              <Image
                width={collapsed ? 60 : 82.57}
                src={BSRM_LOGO}
                alt={APP_NAME}
              />
            )}
            {/* <br />
            <br />
            <br /> */}
          </div>
        </Sider>
      </div>

      <Layout className="site-layout transition-all custom-layout">
        <Header
          style={{
            padding: 0,
            background: colorBgContainer,
            height: "70px",
            borderBottom: "1px solid #F0F3F9",
          }}
        >
          <div className="flex items-center max-h-full justify-between px-6 py-[10px]">
            <div className="w-16 h-16 flex justify-center items-center">
              <Button
                type="text"
                icon={<BsrmSidebarToggol />}
                onClick={handleShowSidebar}
                className="md:hidden flex justify-center items-center"
              />
              <Button
                type="text"
                icon={collapsed ? <BsrmSidebarToggol /> : <BsrmSidebarToggol />}
                onClick={handleCollapsed}
                className="hidden md:flex justify-center items-center"
              />
            </div>

            <div className="flex items-center gap-[18px]">
              <LanguageSwitch />

              <Dropdown
                trigger={["click"]}
                placement="bottomRight"
                arrow={{ pointAtCenter: true }}
                dropdownRender={() => (
                  <div className="w-64 bg-white shadow rounded-md">
                    <div className="flex items-center justify-between gap-2 py-1.5 px-3 shadow-sm shadow-gray-100 sticky top-0">
                      <span className="text-xs text-header font-medium">
                        Notifications
                      </span>
                      <Link
                        className="text-xs text-body font-medium hover:text-header"
                        to={
                          isRETAILER
                            ? "/retailer/notification"
                            : "/admin/notification"
                        }
                      >
                        View All
                      </Link>
                    </div>

                    <div>
                      <div className="h-48 overflow-y-auto notification">
                        {singleLoading || retailerSingleLoading ? (
                          <Loader />
                        ) : notificationData?.length ? (
                          notificationData?.map((el: any) => (
                            <div
                              className="border border-solid border-others-border border-b-0 border-x-0 first:border-t-0 border-t-[1px]"
                              key={el?._id}
                            >
                              <div className="grid grid-cols-80 gap-1.5 py-1.5 pl-1.5">
                                <div className="flex items-center justify-center">
                                  <img
                                    className="w-10 h-10 object-cover rounded-full"
                                    src={
                                      isRETAILER
                                        ? el?.customerId?.image
                                          ? IMAGE_URL + el?.customerId?.image
                                          : IMG
                                        : el?.retailerId?.proprietorImage
                                        ? IMAGE_URL +
                                          el?.retailerId?.proprietorImage
                                        : IMG
                                    }
                                    alt=""
                                  />
                                </div>
                                <div className="flex justify-between items-center">
                                  <div className="relative -top-0.5">
                                    <h6 className="text-body text-base font-medium leading-tight break-all mb-1">
                                      {isRETAILER
                                        ? el?.customerId?.name
                                        : el?.retailerId?.ownerName}
                                    </h6>

                                    <div className="text-[#7d88f4] text-sm font-normal leading-normal break-all">
                                      {el?.title}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <Empty
                            imageStyle={{ marginBottom: 0, height: 70 }}
                            rootClassName="pt-10"
                            description={
                              <span className="text-[#8797ae] block -mt-2 text-sm font-normal font-roboto leading-7">
                                Empty
                              </span>
                            }
                          />
                        )}
                      </div>
                    </div>
                  </div>
                )}
              >
                <Badge
                  count={
                    isRETAILER
                      ? retailerHeaderNotification?.length
                      : adminHeaderNotification?.length
                  }
                >
                  <Button
                    className="border-none shadow-sm"
                    icon={<BsrmNotificationHeader />}
                    size="middle"
                  />
                </Badge>
              </Dropdown>
              <ProfileMenu />
            </div>
          </div>
        </Header>

        <Content
          style={{
            margin: 20,
            padding: "16px 20px",
            minHeight: 280,
            background: colorBgContainer,
          }}
        >
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default withSidebar(SidebarLayout);
