import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import AXIOS from "../../../helpers/api";
import { BASE_API } from "../../../helpers/apiUrl";
import { toast } from "../../../helpers/toast";
import {
  CommonOthers,
  ErrorMessage,
  GetAllProductCategoryParams,
  GetCommonData,
  GetProductCategoryData,
  Params,
  PostRequest,
  ProductCategoryData,
  SingleDataParams,
} from "../../../types/redux";

// create a product category
export const createProductCategoryAsync = createAsyncThunk<
  null,
  PostRequest<ProductCategoryData, CommonOthers>
>(
  "admin-productCategory/createProductCategory",
  async ({ data, others: { reRender } }, { rejectWithValue }) => {
    try {
      await AXIOS.post(BASE_API.manageProductCategory, data);

      reRender(true);
      toast("success", "Product category create successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Product category create fail");
      return rejectWithValue(error);
    }
  }
);

// get all product category
export const getAllProductCategoryAsync = createAsyncThunk<
  GetCommonData<GetProductCategoryData>,
  Params<GetAllProductCategoryParams>,
  {
    rejectValue: ErrorMessage;
  }
>(
  "admin-productCategory/getAllProductCategory",
  async ({ params }, { rejectWithValue }) => {
    const { isAllData, ...rest } = params as GetAllProductCategoryParams;

    try {
      const data = await AXIOS.get(`${BASE_API.manageProductCategory}/data`, {
        params: { ...rest },
      });

      return {
        data: data.data?.categories,
        length: data.data?.totalLength,
      };
    } catch (err) {
      // console.error(err);
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Get product category fail");
      return rejectWithValue(error);
    }
  }
);

// update a product category
export const updateProductCategoryAsync = createAsyncThunk<
  null,
  PostRequest<ProductCategoryData, SingleDataParams>
>(
  "admin-productCategory/updateProductCategory",
  async ({ data, others: { reRender, id } }, { rejectWithValue }) => {
    try {
      await AXIOS.patch(`${BASE_API.manageProductCategory}/${id}`, data);

      reRender && reRender(true);
      toast("success", "Product category update successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Product category update fail");
      return rejectWithValue(error);
    }
  }
);

// delete a product category
export const deleteProductCategoryAsync = createAsyncThunk<
  null,
  Params<SingleDataParams>
>(
  "admin-productCategory/deleteProductCategory",
  async ({ params }, { rejectWithValue }) => {
    try {
      await AXIOS.delete(`${BASE_API.manageProductCategory}/${params?.id}`);

      params?.reRender && params?.reRender(true);
      toast("success", "Product category delete successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", "Product category delete fail");
      return rejectWithValue(error);
    }
  }
);
