import { SearchOutlined } from "@ant-design/icons";
import { Input } from "antd";
import { useCallback, useEffect, useState } from "react";
import BsrmAddIcon from "../../../assets/icons/BsrmAddIcon";
import FaqEditIcon from "../../../assets/icons/FaqEditIcon";
import FaqUserIcon from "../../../assets/icons/FaqUserIcon";
import IconButton from "../../../components/common/IconButton";
import Loader from "../../../components/common/Loader";
import FaqCategoryModal from "../../../components/FaqAccordino/FaqCategory";
import useDebounce from "../../../hooks/useDebounce";
import useModal from "../../../hooks/useModal";
import AdminLayout from "../../../layouts/AdminLayout";
import { getAllFaqAsync } from "../../../store/features/Faq/faqAPI";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { FaqCategory, GetFaqData } from "../../../types/redux/ADMIN/faq";
import FaqAccounts from "./FaqComponents/FaqAccounts";

export default function FAQ() {
  const dispatch = useAppDispatch();
  const [tabClick, setTabClick] = useState("");
  const { loading, faqs } = useAppSelector((state) => state.faqs);
  const { open, toggle, handleOpen } = useModal();
  const { keyword, handleInputChange } = useDebounce();

  const getData = useCallback(() => {
    dispatch(
      getAllFaqAsync({
        params: {
          pageNo: 0,
          limit: 0,
        },
      })
    );
  }, [dispatch]);

  useEffect(() => {
    getData();
  }, [getData]);

  function reRender(val: boolean) {
    if (val) {
      getData();
      toggle();
    }
  }

  const transformFaqData = (data: GetFaqData[]): FaqCategory[] => {
    return data.map((faq) => ({
      status: true, // or any other appropriate value
      __v: 0, // or any other appropriate value
      _id: faq?._id,
      category: faq?.category,
      faqs: faq?.faqs?.map((f) => ({
        _id: f?._id,
        name: f?.name,
        Details: f?.Details,
      })),
      sorting: faq?.sorting, // Ensure this is a number
      icon: faq?.icon,
      createdAt: faq?.createdAt,
      updatedAt: faq?.updatedAt,
    }));
  };

  const transformedFaqs = transformFaqData(faqs);

  useEffect(() => {
    if (faqs?.[0]?._id) {
      setTabClick(faqs?.[0]?._id);
    }
  }, [faqs]);

  const filteredFaqs = transformedFaqs?.filter((faq) => faq?._id === tabClick);

  const searchFAQs = transformedFaqs?.filter((el) =>
    el?.category?.toLowerCase()?.includes(keyword?.toLowerCase())
  );

  return (
    <AdminLayout
      title="Faq" // browser title
      headerTitle="FAQs" // page header
      className="dashboard"
    >
      {open?.type === "ADD" || open?.type === "EDIT" ? (
        <FaqCategoryModal
          open={open?.open}
          title="Create FAQ’s Category"
          handleClose={toggle}
          reRender={reRender}
          type={open?.type}
          data={open?.data}
        />
      ) : null}

      {loading ? (
        <div className="pb-20">
          <Loader />
        </div>
      ) : null}

      <section className="flex gap-4">
        <div className="flex-auto w-[344px] rounded-[14px]">
          <div className="bg-accent rounded-t-[14px] p-4">
            <div className=" flex justify-between ">
              <p className="text-[20px] font-semibold leading-8 text-body">
                Category
              </p>
              <IconButton
                nextIcon={<BsrmAddIcon />}
                onClick={() =>
                  handleOpen({
                    type: "ADD",
                  })
                }
              >
                Add More
              </IconButton>
            </div>
            <div className="mt-4">
              <Input
                className=""
                size="large"
                placeholder="Search by name"
                suffix={<SearchOutlined />}
                onChange={handleInputChange}
              />
            </div>
          </div>

          {searchFAQs.map((faq) => (
            <div
              key={faq?._id}
              className={`${
                tabClick === faq?._id ? "bg-[#EEFBF9]" : ""
              }  px-4 py-2 mt-2.5  cursor-pointer select-none`}
            >
              <div className="flex justify-between">
                <div
                  className="flex gap-3 items-center w-full"
                  onClick={() => setTabClick(faq?._id)}
                >
                  <div className="rounded-full w-14 h-14 bg-primary-admin text-white flex items-center justify-center">
                    <FaqUserIcon />
                  </div>
                  <div className="">
                    <p className="text-[18px] font-medium leading-[30px] text-body">
                      {faq?.category}
                    </p>
                    <p className="text-[14px] font-medium leading-6 text-[#159383]">
                      Total Question: {faq?.faqs?.length}
                    </p>
                  </div>
                </div>
                <button
                  className="h-6 border-none bg-transparent cursor-pointer relative z-20"
                  onClick={() => {
                    handleOpen({
                      type: "EDIT",
                      data: faq,
                    });
                  }}
                >
                  <FaqEditIcon />
                </button>
              </div>
            </div>
          ))}
        </div>

        <FaqAccounts reRender={reRender} faqs={filteredFaqs} />
        {/* {tabClick === "ACCOUNT" && (
        )}
        {tabClick === "ORDERS" && <FaqOrders faqs={filteredFaqs} />}
        {tabClick === "DELIVERY" && <FaqDelivery faqs={filteredFaqs} />}
        {tabClick === "PAYMENT" && <FaqPayment faqs={filteredFaqs} />}
        {tabClick === "REFUND & RETURN" && <FaqRefund faqs={filteredFaqs} />} */}
      </section>

      {/* {(open.type === "CATEGORY" ||
        open.type === "QUESTION") && (
        <FaqCategoryForm
         
          open={open.open}
          data={open?.data}
          handleClose={toggle}
          reRender={reRender}
          type={open.type}
        />
      )} */}
    </AdminLayout>
  );
}
