import { Button, Form, FormProps, Input, Select } from "antd";
import { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import BsrmAttachment from "../../assets/icons/BsrmAttachment";
import BsrmTableHeader from "../../assets/icons/BsrmTableHeader";
import { APP_NAME, IMAGE_URL } from "../../helpers/config";
import {
  createHash,
  removeHash,
  selectSearchOption,
} from "../../helpers/siteConfig";
import {
  createExpertCategoryAsync,
  updateExpertCategoryAsync,
} from "../../store/features/expertCategory/expertCategoryAPI";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { CustomModalProps } from "../../types";
import { GetExpertCategoryData } from "../../types/redux";
import { stringToArray } from "../../utils/array";
import { sliceString } from "../../utils/string";
import CustomModal from "../common/CustomModal";
import FileUpload from "../common/FileUpload";
import Image from "../common/Image";
import { formats } from "../Editor/Formats";
import { modules } from "../Editor/Modules";

type Props = {
  type: "ADD" | "EDIT" | "VIEW";
  reRender: (val: boolean) => void;
} & CustomModalProps;

export default function ExpertCategoryCreateForm({
  reRender,
  type,
  data,
  ...rest
}: Props) {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();

  const [uploadingIcon, setUploadingIcon] = useState(false);
  const [uploadingBanner, setUploadingBanner] = useState(false);
  const [iconName, setIconName] = useState("Upload Icon");
  const [bannerName, setBannerName] = useState("Upload Banner");
  const { postLoading, updateLoading } = useAppSelector(
    (state) => state.expertCategory
  );

  const categoryData = data as GetExpertCategoryData;
  const onFinish: FormProps<any>["onFinish"] = (values) => {
    const { details, ...rest } = values;

    const newValues = {
      ...rest,
      details: createHash(details),
    };

    if (type === "EDIT" && categoryData?._id) {
      dispatch(
        updateExpertCategoryAsync({
          data: newValues,
          others: {
            reRender,
            id: categoryData?._id,
          },
        })
      );
    } else {
      dispatch(
        createExpertCategoryAsync({
          data: newValues,
          others: {
            reRender,
          },
        })
      );
    }
  };

  // add value in modal for view and edit
  useEffect(() => {
    if (
      (type === "EDIT" || type === "VIEW") &&
      categoryData?._id &&
      updateLoading === false
    ) {
      const fileNameArr = stringToArray(categoryData?.icon, ".");
      setIconName(
        categoryData?.icon
          ? `${sliceString(categoryData?.icon, 15, "...")}.${
              fileNameArr?.[fileNameArr?.length - 1]
            }`
          : "Upload Icon"
      );

      form.setFieldsValue({
        name: categoryData?.name || "",
        label: categoryData?.label || "",
        icon: categoryData?.icon || "",
        banner: categoryData?.banner,
        details: categoryData?.details ? removeHash(categoryData?.details) : "",
        sorting: categoryData?.sorting || "",
      });
    }
  }, [
    categoryData?._id,
    categoryData?.icon,
    categoryData?.label,
    categoryData?.name,
    categoryData?.banner,
    categoryData?.details,
    categoryData?.sorting,
    form,
    type,
    updateLoading,
  ]);

  return (
    <CustomModal width={750} {...rest}>
      <div className="p-6">
        <Form
          className="custom-form custom-placeholder-form hide_star_mark"
          layout="vertical"
          onFinish={onFinish}
          size="large"
          form={form}
        >
          <div className="grid grid-cols-2 gap-x-4">
            <Form.Item
              name="name"
              label="Category name"
              rules={[
                {
                  required: true,
                  message: "Please enter category name",
                },
              ]}
            >
              <Input
                size="large"
                disabled={type === "VIEW"}
                placeholder="Name"
              />
            </Form.Item>

            <Form.Item
              name="label"
              label="Category Label"
              rules={[
                {
                  required: true,
                  message: "Please enter category label",
                },
              ]}
            >
              <Input
                size="large"
                disabled={type === "VIEW"}
                placeholder="Title"
              />
            </Form.Item>

            <div className="">
              <Form.Item
                className="custom__image__input__placeholder"
                name="icon"
                label="Select Icon"
                htmlFor="..."
                extra={
                  type !== "VIEW"
                    ? "Upload the icon carefully | SVG, PNG"
                    : null
                }
                valuePropName="fileUrl"
                rules={[
                  {
                    required: true,
                    message: "Please upload a icon",
                  },
                ]}
              >
                <FileUpload
                  errorMessage="Product icon upload fail"
                  disabled={type === "VIEW"}
                  handleLoading={(val) => setUploadingIcon(val)}
                  handleName={(val) => setIconName(val || "Upload Icon")}
                  onChange={(url) => form.setFieldsValue({ icon: url })}
                >
                  <Button
                    size="large"
                    disabled={type === "VIEW"}
                    style={{ width: "100%" }}
                  >
                    <div className="flex justify-between">
                      <span className="flex gap-3 items-center">
                        <BsrmTableHeader />
                        {uploadingIcon ? "Uploading..." : iconName}
                      </span>
                      <BsrmAttachment />
                    </div>
                  </Button>
                </FileUpload>
              </Form.Item>

              {type === "EDIT" || type === "VIEW" ? (
                <div className="mb-4">
                  <Image
                    width={74}
                    height={74}
                    src={`${IMAGE_URL}${form.getFieldValue("icon")}`}
                    alt={APP_NAME}
                    className="bg-primary-admin !rounded-full p-2.5 border-4 border-solid border-[#B0E4CF]"
                    rootClassName="text-center"
                  />
                </div>
              ) : null}
            </div>

            <div className="">
              <Form.Item
                className="custom__image__input__placeholder"
                name="banner"
                label="Select Banner"
                htmlFor="..."
                extra={
                  type !== "VIEW"
                    ? "Upload the icon carefully | SVG, PNG"
                    : null
                }
                valuePropName="fileUrl"
                rules={[
                  {
                    required: true,
                    message: "Please upload a banner",
                  },
                ]}
              >
                <FileUpload
                  errorMessage="Product Banner upload fail"
                  disabled={type === "VIEW"}
                  handleLoading={(val) => setUploadingBanner(val)}
                  handleName={(val) => setBannerName(val || "Upload Banner")}
                  onChange={(url) => form.setFieldsValue({ banner: url })}
                >
                  <Button
                    size="large"
                    disabled={type === "VIEW"}
                    style={{ width: "100%" }}
                  >
                    <div className="flex justify-between">
                      <span className="flex gap-3 items-center">
                        <BsrmTableHeader />
                        {uploadingBanner ? "Uploading..." : bannerName}
                      </span>
                      <BsrmAttachment />
                    </div>
                  </Button>
                </FileUpload>
              </Form.Item>

              {type === "EDIT" || type === "VIEW" ? (
                <div className="mb-4">
                  <Image
                    width={140}
                    height={84}
                    src={`${IMAGE_URL}${form.getFieldValue("banner")}`}
                    alt={APP_NAME}
                    rootClassName="text-center"
                  />
                </div>
              ) : null}
            </div>

            <Form.Item
              name="sorting"
              label="Sorting"
              className="col-span-2"
              rules={[
                {
                  required: true,
                  message: "Please select sort",
                },
              ]}
            >
              <Select
                placeholder="Select one"
                size="large"
                allowClear
                showSearch
                filterOption={selectSearchOption}
                options={[...Array(20)].map((_, idx) => ({
                  label: `${idx + 1}`,
                  value: `${idx + 1}`,
                }))}
                disabled={type === "VIEW"}
              />
            </Form.Item>

            <Form.Item
              name="details"
              label="Category Details"
              className="md:col-span-2"
            >
              <ReactQuill
                className="h-[100px] w-full"
                theme="snow"
                formats={formats}
                modules={modules}
                readOnly={type === "VIEW"}
              />
            </Form.Item>
          </div>

          <br />
          <br />
          {type !== "VIEW" ? (
            <div className="flex justify-center">
              <Button
                className="mt-2 px-[15px] py-[8px] rounded-lg text-[14px] font-semibold leading-6  "
                type="primary"
                htmlType="submit"
                loading={postLoading || updateLoading}
              >
                {type === "EDIT" ? "Update Now" : "Create Now"}
              </Button>
            </div>
          ) : null}
        </Form>
      </div>
    </CustomModal>
  );
}
