import { ApexOptions } from "apexcharts";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import BsrmTaka from "../../assets/icons/dashboard/BsrmTaka";
import { numberShort } from "../../utils/number";

type SalesData = {
  _id: number;
  totalperDay: number;
};

function AverageSales({ data }: { data: SalesData[] | undefined }) {
  const [load, setLoad] = useState(true);

  // total daily sale
  function sel_sum(data: any) {
    return data?.reduce((sum: any, item: any) => sum + item.totalperDay, 0);
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoad(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  const newData = Array.isArray(data)
    ? [...data].sort((a, b) => a._id - b._id)
    : [];

  const series = [
    {
      data: newData.map((item) => item.totalperDay),
    },
  ];

  const options: ApexOptions = {
    chart: {
      height: 350,
      type: "bar",
    },
    plotOptions: {
      bar: {
        columnWidth: 10,
        borderRadius: 4,
        // dumbbellColors: [["#008FFB", "#00E396"]],
        dataLabels: {
          hideOverflowingLabels: false,
          position: "top",
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#07bfa5"],

    xaxis: {
      categories: ["S", "M", "T", "W", "T", "F", "S"],
      position: "bottom",
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      crosshairs: {
        fill: {
          type: "gradient",
          gradient: {
            colorFrom: "#D8E3F0",
            colorTo: "#BED1E6",
            // gradientToColors: ['#00E396'],
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5,
          },
        },
      },
      tooltip: {
        enabled: true,
      },
      labels: {
        style: {
          colors: "#5e718d",
          fontSize: "14px",
          fontFamily: "Roboto",
        },
      },
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        formatter: function (val) {
          return val + "%";
        },
      },
    },
    grid: {
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    title: {
      text: "Average Daily Sales",
      align: "left",
      style: {
        color: "#5e718d",
        fontSize: "16px",
        fontFamily: "Roboto",
        fontWeight: "500",
      },
    },
    tooltip: {
      shared: false,
      custom: function ({ series, seriesIndex, dataPointIndex }) {
        return `<div class="px-3 py-2 bg-[#2d3643] rounded-md text-[#f0f3f8] text-sm font-medium font-Roboto">
                  ${numberShort(series[seriesIndex][dataPointIndex])}
                </div>`;
      },
    },
  };

  return (
    <div>
      <div className="flex items-center gap-2">
        <p className="text-[22px] font-semibold leading-9 text-body ">
          <BsrmTaka /> {sel_sum(data)?.toLocaleString("en-IN")}
        </p>
        {/* <p className="text-[#ff574c] bg-[#fff4f4] px-1.5 py-1 text-[14px] font-medium leading-6">
          <span className="mr-[6px]">
            <DownIcon />
          </span>
          <span>02%</span>
        </p> */}
      </div>
      <div id="chart">
        {!load && (
          <ReactApexChart
            options={options}
            series={series}
            type="bar"
            height={350}
            width={"100%"}
          />
        )}
      </div>
    </div>
  );
}

export default AverageSales;
