import { Badge, Divider } from "antd";
import { ReactNode, useEffect } from "react";
import { APP_NAME, IMAGE_URL } from "../../helpers/config";
import { getSingleExpertAsync } from "../../store/features/expert/expertAPI";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { CustomModalProps } from "../../types";
import { formatDate } from "../../utils/time";
import CustomModal from "../common/CustomModal";
import Image from "../common/Image";

type Props = {
  type: "ADD" | "EDIT" | "VIEW";
  children?: ReactNode;
  reRender: (val: boolean) => void;
} & CustomModalProps;

export default function ExpertViewDetails({
  reRender,
  type,
  children,
  data,
  ...rest
}: Props) {
  const dispatch = useAppDispatch();
  const { singleExpert } = useAppSelector((state) => state.expert);

  useEffect(() => {
    dispatch(
      getSingleExpertAsync({
        params: {
          id: data?._id,
        },
      })
    );
  }, [data._id, dispatch]);

  return (
    <CustomModal width={460} {...rest}>
      <div className="p-6 ">
        <div className="flex justify-center mb-[10px] mt-1">
          <Badge rootClassName="custom_badge" dot>
            <Image
              width={135}
              height={135}
              src={`${IMAGE_URL}${singleExpert?.image}`}
              alt={APP_NAME}
              className="!rounded-full"
            />
          </Badge>
        </div>
        <div className="mb-2 flex justify-center">
          <div className="text-center">
            <h3 className="paragraph__heading">{singleExpert?.fullName}</h3>
            <p className="paragraph__heading text-[14px] text-[#5E718D]">
              {singleExpert?.designation}
            </p>
            <p className="text-[14px] font-medium leading-6 text-[#5E718D]">
              Profile Status-{" "}
              <span className="text-[#11A75C] font-semibold">
                {singleExpert?.status}
              </span>
            </p>
          </div>
        </div>

        <Divider className="bg-[#D7DFE9]" />

        <div className="flex justify-center -mt-5">
          <table className="w-[365px]  border-separate border-spacing-2 ">
            <tbody className="text-[14px] font-semibold leading-6 text-body">
              <tr>
                <td
                  className="border border-slate-700 flex"
                  style={{ whiteSpace: "nowrap", width: "150px" }}
                >
                  Email Address :
                </td>
                <td
                  className="border border-slate-700 ..."
                  style={{ whiteSpace: "normal", wordBreak: "break-word" }}
                >
                  {singleExpert?.email}
                </td>
              </tr>
              <tr>
                <td className="border border-slate-700 ...">Mobile Number :</td>
                <td className="border border-slate-700 ...">
                  +88 {singleExpert?.phoneNumber}
                </td>
              </tr>
              <tr>
                <td className="border border-slate-700 ...">Division :</td>
                <td className="border border-slate-700 ...">
                  {singleExpert?.divisionId?.name}
                </td>
              </tr>
              <tr>
                <td className="border border-slate-700 ...">Address :</td>
                <td className="border border-slate-700 ...">
                  {singleExpert?.address}
                </td>
              </tr>
              <tr>
                <td className="border border-slate-700 ...">Education :</td>
                <td className="border border-slate-700 ...">
                  {singleExpert?.educationDetails}
                </td>
              </tr>
              <tr>
                <td className="border border-slate-700 ...">Experience :</td>
                <td className="border border-slate-700 ...">5 Years</td>
              </tr>
              <tr>
                <td className="border border-slate-700 ...">Joined :</td>
                <td className="border border-slate-700 ...">
                  {formatDate(singleExpert?.createdAt)}
                </td>
              </tr>
              <tr>
                <td className="border border-slate-700 ...">Client Served :</td>
                <td className="border border-slate-700 ...">200+</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </CustomModal>
  );
}
