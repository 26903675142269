import { useEffect, useState } from "react";
import BsrmAddIcon from "../../../../assets/icons/BsrmAddIcon";
import IconButton from "../../../../components/common/IconButton";
import Accordion from "../../../../components/FaqAccordino/Accordion";
import FaqQuestionModal from "../../../../components/FaqAccordino/FaqQuestionModal";
import { removeHash } from "../../../../helpers/siteConfig";
import useModal from "../../../../hooks/useModal";
import { FaqCategory } from "../../../../types/redux/ADMIN/faq";

// Component definition
export default function FaqAccounts({
  faqs,
  reRender,
}: {
  faqs: FaqCategory[];
  reRender: (val: boolean) => void;
}) {
  const [faqTitle, setFaqTitle] = useState("");
  const { open, toggle, handleOpen } = useModal();

  useEffect(() => {
    if (faqs?.length > 0) {
      setFaqTitle(faqs[0]?.category);
    }
  }, [faqs]);

  function reRenderToggle(val: boolean) {
    if (val) {
      reRender(val);
      toggle();
    }
  }

  return (
    <>
      {open?.type === "ADD" || open?.type === "EDIT" ? (
        <FaqQuestionModal
          open={open?.open}
          title="Add new questions"
          handleClose={toggle}
          reRender={reRenderToggle}
          type={open?.type}
          data={faqs?.[0]}
          changeId={open?.data}
        />
      ) : null}
      <div className="flex-auto w-[712px] rounded-[14px]">
        <div className="bg-accent flex justify-between rounded-t-[14px] p-4">
          <p className="text-[20px] font-semibold leading-8 text-body">
            {faqTitle}
          </p>
          <IconButton
            nextIcon={<BsrmAddIcon />}
            onClick={() =>
              handleOpen({
                type: "ADD",
              })
            }
          >
            Add New Question
          </IconButton>
        </div>

        {/* Example usage of Accordion component */}
        {faqs?.map((category) => (
          <div key={category?._id}>
            {category?.faqs?.map((faq) => (
              <Accordion
                key={faq?._id}
                question={faq?.name}
                answer={removeHash(faq?.Details)}
                handleOpen={() =>
                  handleOpen({
                    type: "EDIT",
                    data: faq?._id,
                  })
                }
              />
            ))}
          </div>
        ))}
      </div>
    </>
  );
}
