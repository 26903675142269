import { useState } from "react";

export default function LanguageSwitch() {
  const [active, setActive] = useState("ENG");

  const handleActive = () => {
    setActive((prev) => (prev === "ENG" ? "বাং" : "ENG"));
  };

  return (
    <button
      className="w-[92px] h-7 cursor-pointer rounded-[100px] border-2 border-solid border-primary-admin justify-start items-start inline-flex"
      onClick={handleActive}
    >
      <div
        className={`h-full px-2 py-0.5 w-2/4 rounded-tl-[100px] rounded-bl-[100px] justify-center items-center flex transition-colors duration-300 ${
          active === "বাং"
            ? "bg-primary-admin text-white"
            : "bg-white text-header"
        }`}
      >
        <div className="mt-0.5 text-lg font-normal font-['Kalpurush'] leading-normal">
          বাং
        </div>
      </div>

      <div
        className={`h-full px-2 py-0.5 w-2/4 rounded-tr-[100px] rounded-br-[100px] justify-center items-center flex transition-colors duration-300 ${
          active === "ENG"
            ? "bg-primary-admin text-white"
            : "bg-white text-header"
        }`}
      >
        <div className="text-sm font-normal font-['Roboto'] leading-normal">
          ENG
        </div>
      </div>
    </button>
  );
}
