import { Button, Image, Select, Space } from "antd";
import { ColumnsType } from "antd/es/table";
import { useCallback, useEffect, useState } from "react";
import BsrmAddIcon from "../../../assets/icons/BsrmAddIcon";
import BsrmEdit from "../../../assets/icons/BsrmEdit";
import BsrmEyeIcon from "../../../assets/icons/BsrmEyeIcon";
import BsrmTableHeader from "../../../assets/icons/BsrmTableHeader";
import PlaceholderImage from "../../../assets/images/placeholder.png";
import ProductCategoryForm from "../../../components/ModalForm/ProductCategoryForm";
import CustomTable from "../../../components/common/CustomTable";
import DeleteModal from "../../../components/common/DeleteModal";
import IconButton from "../../../components/common/IconButton";
import { APP_NAME, IMAGE_URL } from "../../../helpers/config";
import { addKeyInArray, selectSearchOption } from "../../../helpers/siteConfig";
import useModal from "../../../hooks/useModal";
import useTable from "../../../hooks/useTable";
import AdminLayout from "../../../layouts/AdminLayout";
import {
  deleteProductCategoryAsync,
  getAllProductCategoryAsync,
} from "../../../store/features/productCategory/productCategoryAPI";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { GetProductCategoryData, TableData } from "../../../types/redux";

export default function Category() {
  const dispatch = useAppDispatch();
  const [selectType, setSelectType] = useState<"Product" | "Others">();
  const [selectStatus, setSelectStatus] = useState("");
  const [selectCategory, setSelectCategory] = useState("");
  const { open, toggle, handleOpen } = useModal();
  const { limit, page, handlePagination, handleLimit } = useTable();

  const {
    loading,
    countProductCategory,
    productCategory,
    deleteLoading,
    productAllCategory,
  } = useAppSelector((state) => state.productCategory);

  const columns: ColumnsType<TableData<GetProductCategoryData>> = [
    {
      title: "No.",
      align: "center",
      width: "50px",
      render: (_, __, idx) => idx + 1 + (page - 1) * limit,
    },
    {
      title: () => <BsrmTableHeader />,
      dataIndex: "banner",
      align: "center",
      width: "100px",
      render: (val) => (
        <div>
          <Image
            width={70}
            height={50}
            preview={false}
            alt={APP_NAME}
            src={`${IMAGE_URL}${val}`}
            fallback={PlaceholderImage}
          />
        </div>
      ),
    },
    {
      title: "Category Name",
      dataIndex: "name",
    },
    {
      title: "Category Label",
      dataIndex: "label",
    },
    {
      title: "Parent Category",
      dataIndex: "parentCategoryId",
      render: (val) => val?.name || "-",
    },
    {
      title: "Type",
      dataIndex: "categoryType",
    },
    {
      title: "Icon",
      dataIndex: "icon",
      align: "center",
      width: "100px",
      className: "table__head__icon__bg__highlight",
      render: (val) => (
        <div>
          <img className="w-[30px]" src={`${IMAGE_URL}${val}`} alt="" />
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      align: "center",
      render: (val) => (
        <span className={`status ${val ? "status-done" : "status-danger"}`}>
          {val ? "Active" : "Inactive"}
        </span>
      ),
    },
    {
      title: "Action",
      align: "left",
      width: "150px",
      render: (val: GetProductCategoryData) => (
        <div className="flex justify-center gap-2">
          <Button
            icon={<BsrmEyeIcon />}
            className="bg-white py-[6px]"
            onClick={() =>
              handleOpen({
                type: "VIEW",
                data: val,
              })
            }
          />
          <Button
            icon={<BsrmEdit />}
            className="bg-white py-[6px]"
            onClick={() =>
              handleOpen({
                type: "EDIT",
                data: val,
              })
            }
          />
        </div>
      ),
    },
  ];

  const handleDelete = (id: string) => {
    dispatch(
      deleteProductCategoryAsync({
        params: {
          id,
          reRender,
        },
      })
    );
  };

  const getData = useCallback(() => {
    dispatch(
      getAllProductCategoryAsync({
        params: {
          pageNo: page,
          limit: limit,
          status: selectStatus,
          parentCategoryId: selectCategory,
          categoryType: selectType,
        },
      })
    );
  }, [dispatch, limit, page, selectCategory, selectStatus, selectType]);

  useEffect(() => {
    dispatch(
      getAllProductCategoryAsync({
        params: {
          pageNo: 0,
          limit: 0,
          status: "",
          parentCategoryId: "",
          isAllData: true,
        },
      })
    );
  }, [dispatch]);

  useEffect(() => {
    getData();
  }, [getData]);

  function reRender(val?: boolean) {
    toggle();

    if (val) {
      getData();
    }
  }

  const allStatus = [
    { label: "Active", value: "true" },
    {
      label: "Inactive",
      value: "false",
    },
  ];
  interface ProductCategory {
    parentCategoryId: {
      _id: string;
      name: string;
    };
  }

  const filterByParentCategory = (productAllCategory: ProductCategory[]) => {
    return Array.from(
      new Map(
        productAllCategory
          .map((el) => ({
            label: el?.parentCategoryId?.name?.trim(),
            value: el?.parentCategoryId?._id?.trim(),
          }))
          .filter((item) => item.label && item.value) // Remove entries with empty label or value
          .map((item) => [item.value, item]) // Map based on value (which is _id)
      ).values() // Extract unique options
    );
  };

  return (
    <AdminLayout
      title="Category"
      headerTitle="Category List"
      className="dashboard"
      headerChildren={
        <Space size="middle">
          <IconButton
            nextIcon={<BsrmAddIcon />}
            onClick={() =>
              handleOpen({
                type: "ADD",
              })
            }
          >
            Create New
          </IconButton>
        </Space>
      }
    >
      <CustomTable<TableData<GetProductCategoryData>>
        loading={loading}
        total={countProductCategory}
        limit={limit}
        page={page}
        handlePagination={handlePagination}
        columns={columns}
        dataList={addKeyInArray(productCategory)}
        handleLimit={handleLimit}
        tableTopChildren={
          <Space size="small" className="filtering__select__option">
            <Select
              placeholder="Filter By Type"
              className="filter_select_width"
              allowClear
              showSearch
              filterOption={selectSearchOption}
              options={[
                {
                  label: "Product",
                  value: "Product",
                },
                {
                  label: "Others",
                  value: "Others",
                },
              ]}
              onChange={setSelectType}
            />
            <Select
              placeholder="Filter By Parent Category"
              className="filter_select_width"
              allowClear
              showSearch
              filterOption={selectSearchOption}
              options={filterByParentCategory(productAllCategory)}
              onChange={setSelectCategory}
            />
            <Select
              className="filter_select_width"
              placeholder="Status"
              allowClear
              showSearch
              filterOption={selectSearchOption}
              options={allStatus}
              onChange={setSelectStatus}
            />
          </Space>
        }
      />

      {/* Modal */}
      {(open.type === "ADD" ||
        open.type === "EDIT" ||
        open.type === "VIEW") && (
        <ProductCategoryForm
          title={
            open.type === "EDIT"
              ? "Update Category"
              : open.type === "VIEW"
              ? "View Category"
              : "Create Category"
          }
          open={open.open}
          data={open?.data}
          handleClose={toggle}
          reRender={reRender}
          type={open.type}
        />
      )}

      {open.type === "DELETE" && (
        <DeleteModal
          title="Delete Product Category"
          open={open.open}
          data={open?.data}
          handleClose={toggle}
          handleYes={handleDelete}
          deleteLoading={deleteLoading}
        />
      )}
    </AdminLayout>
  );
}
